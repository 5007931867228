import { navbarType } from "../../../Types";
import NavbarBuildingView from "./NavbarBuildingView";
import NavbarRoomView from "./NavbarRoomView";



const NavbarTypes = (props: navbarType) => {
    const {
        isToggleOpen,
        handleUpdateToggleState
    } = props;
    return (
        <div className="buliding_outerItem">
            <NavbarBuildingView
                isToggleOpen={isToggleOpen}
                handleUpdateToggleState={handleUpdateToggleState}
            />
            <NavbarRoomView
                isToggleOpen={isToggleOpen}
                handleUpdateToggleState={handleUpdateToggleState}
            />
        </div>
    );
}

export default NavbarTypes;