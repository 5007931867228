import { useState, useEffect, Fragment } from "react";
import DollRoofView from "./DollRoofView";
import RoofViewHandler from "./RoofViewHandler";
import CameraRotationView from "./CameraRotationView";
import RoofViewSvgIcons from "../mobileLandscape/LeftSection/RoofViewSvgIcons";
import AnimationGifs from "../Common/AnimationGifs";
import HelpSection from "../Common/HelpSection";
import HelpLeftIcon from "../SvgIcons/HelpLeftIcon";

const RoofView = ({handleRoofViewCallBack, helpButtonToggle}:any) => {
  const {
    isOpen,
    activeRoofView,
    setIsOpen,
    handleRoofView
  } = RoofViewHandler();

  const [isHover, setIsHover] = useState(false);
  const [showGif, setShowGif] = useState(false);

  const showGifs = () => {
    setShowGif(true);
    setTimeout(() => {
      setShowGif(false);
    }, 3000);
  };

  useEffect(() => {
    if (isOpen) {
      showGifs();
    }
  }, [isOpen]);

  return (
    <>
      <div className="rotate_device">
        {showGif && (
          <AnimationGifs  />
        )}
      </div>
     
      <div className="roofMode_outer">

        {/* <div className="back_close_btn">
          <button><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 12 20" fill="none">
            <path d="M10.2188 1.5625L1.78125 10L10.2188 18.4375" stroke="white" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
          </svg> Back</button>
          <button  onClick={closeActiveUi}><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 16 16" fill="none">
            <path d="M1.75 1.75L14.25 14.25M1.75 14.25L14.25 1.75" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg> Close</button>
        </div> */}
        <div className="roof_div">
          <div
            onClick={() => {
              handleRoofView();
              setIsOpen(!isOpen);
              handleRoofViewCallBack(!isOpen)
            }}
            className={`roofDiv_1 step step-10 ${isOpen && "active_roofMode"}`}
            onMouseOver={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            <RoofViewSvgIcons
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill={isHover ? "#3A3A3A" : "white"}
            />
            {isHover && helpButtonToggle && <HelpSection text="Switch to roof-top view" iconSpace="right_helpTooltip" className="roofView_toggle" icon={<HelpLeftIcon />}/>}
          </div>
          <div className="step step-11"><DollRoofView isRoofViewOpen={isOpen} helpButtonToggle={helpButtonToggle} /></div>
        </div>
      </div>
      {isOpen && activeRoofView === "Active" && <CameraRotationView />}
    </>
  );
};

export default RoofView;
