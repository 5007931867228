


const HelpLeftIcon = () => {
    return (
        <svg width="33" height="15" viewBox="0 0 33 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.4769 9.48187C21.7714 13.0577 12.7937 12.177 5.80631 7.49255C6.17353 8.90245 6.38746 10.3389 6.60139 11.7753C6.63131 11.9303 5.27341 12.7739 5.10741 12.8987C4.94142 13.0235 3.63885 13.8255 3.61344 14.0221C3.11673 10.1362 2.10482 6.37144 0.424409 2.75443C0.30924 2.48608 3.4722 0.817567 3.35703 0.549216L3.39966 0.605901C3.51032 0.522686 3.66361 0.496154 3.66361 0.496154C6.90073 1.34529 10.2186 1.95624 13.4893 2.15893C13.1954 2.11369 12.272 3.0743 11.9953 3.28234C11.7187 3.49038 10.9059 4.36777 10.5014 4.40575C9.25783 4.36471 8.02701 4.22539 6.80889 3.98777C14.061 9.71551 23.9248 11.0834 32.2689 7.20489C31.3537 7.71564 30.5881 9.00128 29.4769 9.48187Z" fill="white"/>
        </svg>
    );
}


export default HelpLeftIcon;