import { ActionType } from "../action-types";
import { Action } from "../actions/navbar";
import { NavbarType } from '../../type/NavbarType';

const initialData = {
    "BuildingType":
    {
        "Name": " ",
        "RoomTypes": [
            {
                "Room":
                {
                    "Name": " ",
                    "RoomTypes": [
                        {
                            "RoomType":
                            {
                                "Name": " ",
                                "Cameras": 0,
                                "Components": [
                                    {
                                        "Component": " ",
                                        "DisplayName": " ",
                                        "Type": " "
                                        // "Image": " ",
                                        // "Interaction": " "
                                    },
                                    {
                                        "Component": " ",
                                        "DisplayName": " ",
                                        "Type": " "
                                        // "Image": " ",
                                        // "Interaction": " "
                                    },
                                    {
                                        "Component": " ",
                                        "DisplayName": " ",
                                        "Type": " "
                                        // "Image": " ",
                                        // "Interaction": " "
                                    }
                                ]
                            }
                        },
                        {
                            "RoomType":
                            {
                                "Name": " ",
                                "Cameras": 0,
                                "Components": [
                                    {
                                        "Component": " ",
                                        "DisplayName": " ",
                                        "Type": " "
                                        // "Image": " ",
                                        // "Interaction": " "
                                    },
                                    {
                                        "Component": " ",
                                        "DisplayName": " ",
                                        "Type": " "
                                        // "Image": " ",
                                        // "Interaction": " "
                                    },
                                    {
                                        "Component": " ",
                                        "DisplayName": " ",
                                        "Type": " "
                                        // "Image": " ",
                                        // "Interaction": " "
                                    }
                                ]
                            }
                        },
                        {
                            "RoomType":
                            {
                                "Name": " ",
                                "Cameras": 0,
                                "Components": [
                                    {
                                        "Component": " ",
                                        "DisplayName": " ",
                                        "Type": " ",

                                        // "Image": " ",
                                        // "Interaction": " "
                                    },
                                    {
                                        "Component": " ",
                                        "DisplayName": " ",
                                        "Type": " "
                                        // "Image": " ",
                                        // "Interaction": " "
                                    },
                                    {
                                        "Component": " ",
                                        "DisplayName": " ",
                                        "Type": " "
                                        // "Image": " ",
                                        // "Interaction": " "
                                    }
                                ]
                            }
                        }
                    ],
                    "DefaultRoomType": " ",
                    // "Cameras":[
                    //     {
                    //         "CameraIndex":0
                    //     }
                    // ]
                }
            },

        ],
        "DefaultRoom": " ",
        "RoomCategory": [],
        "DefaultRoomCategory":'',
    }
}


const selectedBuilding = initialData;

export const INITIAL_STATE: NavbarType = {
    buildingnavData: selectedBuilding,
    selectedRoomType: "Furnished",
    setStateData: "",
    Data:{},
    currentActiveRoomtype:""

}

export default (state: NavbarType = INITIAL_STATE, action: Action): NavbarType => {

    switch (action.type) {
        //LOADING EVENTS START HERE
        case ActionType.BUILDING_DATA:
            return { ...state, buildingnavData: action.payload }
        case ActionType.GET_STATE_RESPONSE:
            return { ...state, setStateData: {...state.buildingnavData.BuildingType.RoomTypes.map((item:any) => {                if(item.Room.Name == action.payload.selectedRoom){
                    return item.Room.DefaultRoomType = action.payload.defaultRoomtype
                }
                return item.Room.DefaultRoomType
            })} }
        case ActionType.SELECTED_ROOM_TYPE:
            return { ...state, selectedRoomType : action.payload }
        case ActionType.COMPASS_DATA:
            return {...state,Data:action.payload}
        case ActionType.CURRENT_DEFAULT_ACTIVE_ROOMTYPE :
            return {...state, currentActiveRoomtype : action.payload}
        default:
            return state;
    }
};