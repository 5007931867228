import { useState } from "react";
import { isMobile } from "react-device-detect";
const InputRangeSlider = ({
    rangeValue,
    TimeIcons,
    TimeIcons2,
    currentTab,
    TimeIcons3,
    TimeIcons4,
    handleChange,
    setCurrentModeValue,
    data
}:any) => {
  const {first, second, third, fourth} = data
    return (
        <div className="slidermode">
          <div className="container-slider">
          <input
            type="range"
            min="0.215"
            max="0.760"
            step="0.01"
            value={rangeValue}
            onChange={handleChange}
            id="sss"
          />
          </div>
          <div className="slidericon">
            <ul>
              <li onClick={() => setCurrentModeValue(first, 'first')}>
                <TimeIcons width={30} height={30} viewBox={"0 0 18 18"} fill={currentTab === "first" ? "#ECC503" : "white"}/>
              </li>
              <li onClick={() => setCurrentModeValue(second, 'second')}>
                <TimeIcons2 width={30} height={30} viewBox={"0 0 18 18"} fill={currentTab === "second" ? "#ECC503" : "white"}/>
              </li>
              <li onClick={() => setCurrentModeValue(third, 'third')}>
                <TimeIcons3 width={30} height={30} viewBox={"0 0 18 18"} fill={currentTab === "third" ? "#ECC503" : "white"}/>
              </li>
              <li onClick={() => setCurrentModeValue(fourth, 'fourth')}>
                <TimeIcons4 width={28} height={30} viewBox={"0 0 16 18"} fill={currentTab === "fourth" ? "#ECC503" : "white"}/>
              </li>
            </ul>
          </div>
        </div>
    );
}


export default InputRangeSlider;