import { ActionType } from '../action-types';




export const updateOptionListDetail = (data:any) => {
    return {
        type: ActionType.UPDATE_OPTION_LIST_DETAIL,
        payload: data
    }
}