

const ComponentLogo = () => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="1251.000000pt" height="1251.000000pt" viewBox="0 0 1251.000000 1251.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,1251.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M0 6255 l0 -6255 6255 0 6255 0 0 6255 0 6255 -6255 0 -6255 0 0
                -6255z m12240 -5 l0 -5990 -5990 0 -5990 0 0 5990 0 5990 5990 0 5990 0 0
                -5990z"/>
                <path d="M2265 10948 c-282 -31 -821 -179 -989 -271 -34 -19 -99 -48 -146 -66
                -47 -17 -127 -53 -179 -79 -76 -38 -106 -60 -159 -117 -91 -98 -175 -230 -218
                -343 -20 -54 -45 -114 -55 -136 -16 -34 -17 -59 -12 -210 3 -99 1 -181 -4
                -195 -7 -17 -6 -36 3 -60 7 -20 22 -67 34 -106 12 -38 37 -93 56 -122 20 -28
                44 -75 55 -103 61 -162 160 -292 460 -606 79 -83 162 -174 184 -202 22 -28 50
                -53 61 -56 56 -15 343 -268 416 -368 17 -22 51 -47 85 -64 32 -16 60 -32 61
                -36 2 -4 9 -8 15 -8 24 0 120 -67 138 -97 12 -20 31 -34 50 -38 17 -4 58 -33
                92 -65 91 -84 133 -110 180 -110 37 0 47 -7 137 -94 112 -109 185 -160 255
                -178 34 -9 75 -32 127 -73 84 -66 370 -236 445 -265 102 -39 207 -92 269 -135
                36 -25 68 -45 72 -45 4 0 31 -16 60 -35 132 -89 216 -127 312 -140 39 -5 71
                -19 110 -45 98 -66 473 -243 564 -266 16 -4 64 -26 107 -50 98 -54 107 -59
                144 -72 17 -6 70 -27 119 -47 48 -21 127 -45 175 -55 66 -13 108 -30 175 -69
                49 -28 137 -71 195 -95 125 -52 355 -136 374 -136 7 0 128 -38 269 -84 140
                -46 311 -101 379 -121 68 -20 165 -49 214 -65 50 -16 115 -31 145 -35 56 -6
                252 -69 350 -112 92 -41 568 -144 1093 -238 281 -51 457 -87 625 -131 96 -24
                175 -44 177 -44 12 0 26 -131 35 -337 9 -202 15 -254 33 -310 18 -57 21 -89
                20 -198 -1 -75 -8 -160 -17 -200 -9 -38 -21 -134 -28 -213 -10 -124 -10 -152
                3 -202 15 -56 15 -58 -8 -84 -16 -19 -23 -40 -23 -69 0 -57 -16 -110 -40 -132
                -15 -13 -20 -31 -20 -65 0 -86 -21 -173 -102 -420 -23 -69 -52 -139 -64 -156
                -13 -17 -49 -80 -79 -140 -30 -60 -59 -116 -64 -124 -5 -8 -14 -34 -21 -58
                -13 -49 -63 -110 -129 -155 -25 -17 -70 -53 -98 -80 l-53 -50 -57 12 c-80 16
                -135 52 -197 129 -137 171 -262 419 -325 644 -21 73 -21 79 -5 103 21 33 14
                112 -16 159 -24 40 -26 83 -4 114 13 19 14 28 4 52 -8 20 -10 72 -5 182 6 128
                4 156 -8 173 -13 17 -13 23 2 55 39 79 49 190 26 297 -6 28 -4 33 21 46 16 9
                67 55 113 104 46 48 100 97 119 109 72 41 89 90 77 221 -7 72 -10 80 -44 114
                -44 44 -105 63 -170 54 -26 -3 -110 -37 -188 -76 -79 -38 -150 -69 -158 -69
                -36 0 -124 -57 -185 -119 -74 -75 -135 -188 -193 -357 -42 -119 -53 -224 -63
                -579 -6 -203 -4 -249 15 -390 63 -468 138 -719 260 -870 23 -27 56 -70 73 -95
                82 -111 241 -250 340 -295 115 -52 317 -58 483 -15 44 12 112 26 150 31 89 12
                274 100 395 189 105 77 291 263 392 390 67 85 76 101 81 150 5 39 29 101 86
                220 124 259 160 372 235 748 63 309 72 421 56 684 -15 241 -14 309 2 391 13
                68 6 139 -18 189 -22 47 -13 83 29 105 28 15 69 18 312 21 154 3 368 -1 475
                -7 613 -34 612 -34 775 -22 251 18 465 64 487 104 18 35 -11 44 -160 51 -331
                16 -514 28 -612 42 -151 20 -522 91 -700 134 -87 20 -258 49 -437 74 -79 11
                -113 20 -149 42 -42 27 -48 35 -70 104 -28 89 -38 137 -60 290 -8 63 -22 132
                -29 152 -20 53 -45 164 -45 201 0 59 -40 242 -64 292 -13 28 -27 66 -31 85
                -18 94 -94 285 -135 337 -13 17 -20 41 -20 69 0 55 -34 146 -70 187 -40 46
                -69 110 -77 164 -14 106 -200 420 -328 553 -45 47 -82 96 -97 131 -35 76 -188
                332 -245 410 -27 35 -74 88 -105 118 -32 29 -101 106 -155 170 -207 246 -321
                348 -443 397 -50 21 -56 27 -67 65 -7 23 -22 52 -33 64 -20 22 -157 95 -177
                95 -6 0 -42 37 -80 83 -101 119 -232 222 -408 318 -44 24 -108 64 -141 89 -34
                25 -111 72 -170 104 -60 33 -137 76 -171 97 -34 20 -79 43 -100 49 -54 18
                -104 55 -216 158 -77 72 -105 92 -135 97 -104 16 -146 36 -311 147 -93 64
                -155 96 -199 104 -21 4 -58 22 -84 39 -43 30 -118 59 -338 129 -47 15 -105 42
                -130 60 -47 34 -185 80 -421 142 -69 18 -149 42 -179 54 -76 30 -348 100 -390
                100 -19 0 -60 7 -90 15 -30 9 -88 20 -127 25 -40 5 -92 19 -115 31 -40 21 -76
                27 -272 49 -49 6 -155 23 -235 39 -144 29 -151 29 -591 33 -245 3 -481 1 -525
                -4z m595 -237 c107 -11 242 -25 300 -31 58 -6 296 -47 530 -91 349 -65 460
                -90 621 -139 108 -33 259 -73 335 -89 152 -31 143 -27 385 -154 116 -61 192
                -94 250 -108 207 -53 602 -266 1054 -569 88 -59 198 -132 245 -162 94 -60 369
                -262 385 -283 6 -7 83 -66 170 -130 88 -65 181 -136 206 -159 54 -47 216 -234
                374 -431 61 -76 131 -156 156 -179 25 -22 74 -78 107 -123 34 -45 67 -85 72
                -88 19 -12 171 -207 190 -244 10 -20 30 -47 45 -61 15 -14 47 -54 73 -90 25
                -36 49 -67 52 -70 4 -3 20 -29 36 -59 15 -29 54 -76 85 -105 52 -47 57 -55 63
                -106 10 -75 38 -134 91 -191 46 -49 50 -60 59 -145 5 -57 70 -235 140 -390 35
                -75 49 -121 55 -176 5 -40 15 -87 23 -103 26 -51 106 -290 148 -441 59 -216
                141 -637 126 -651 -5 -5 -368 40 -708 87 -94 13 -180 33 -275 64 -163 53 -426
                122 -537 142 -43 7 -113 27 -155 43 -42 16 -98 32 -126 36 -27 4 -113 31 -190
                60 -318 120 -572 200 -765 240 -57 12 -92 27 -142 61 -104 69 -280 142 -473
                194 -96 26 -357 115 -397 136 -17 9 -79 34 -137 55 -58 22 -121 49 -140 60
                -53 31 -227 99 -309 120 -56 14 -84 28 -119 58 -37 33 -132 81 -161 81 -23 0
                -153 63 -185 91 -21 17 -82 54 -135 81 -54 27 -104 56 -113 64 -8 8 -19 14
                -22 14 -4 0 -26 15 -50 33 -23 17 -60 40 -82 49 -98 42 -184 72 -254 90 -79
                19 -159 64 -184 102 -12 17 -22 22 -45 18 -43 -6 -68 12 -109 76 -44 68 -56
                78 -153 132 -233 128 -342 200 -565 376 -250 196 -275 214 -329 229 -65 19
                -144 87 -215 185 -28 39 -56 70 -62 70 -5 0 -54 42 -107 93 -110 104 -191 174
                -274 235 -72 53 -218 248 -390 522 -21 33 -85 119 -143 192 -66 84 -105 142
                -105 157 0 32 -29 141 -46 171 -25 47 -54 177 -60 271 -7 112 15 278 44 336
                34 66 134 174 248 269 127 106 494 223 844 269 291 38 435 40 750 6z"/>
            </g>
        </svg>


    );
}

export default ComponentLogo;