import { useEffect, useRef, useState } from "react";
import { useActions } from "../../hooks/use-actions";
import SubCategoryDropdown from "./SubCategoryDropdown";
import NavbarHandler from "../leftsection/NavbarHandler";
import { useTypedSelector } from "../../hooks/use-typed-selector";
import HelpSection from "../Common/HelpSection";
import HelpRightIcon from "../SvgIcons/HelpRightIcon";

const SliderComponent = (props: any) => {

    const { handleActiveSubcategory, gettingTotalCameras, helpButtonToggle } = props
    const {retrieveRoomType} = NavbarHandler();
    const { setCameraIndex, DefaultSubcategory, HandleRoomAction } = useActions();

    const [isOpen, setIsOpen] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const [isSliderHover, setIsSliderHover] = useState(false)
    const [hoverRoomCategory, setHoverRoomCategory] = useState('');

    const { 
        buildingnavData:{ 
            BuildingType:{
                RoomCategory 
            }
        }
    } = useTypedSelector(({ navbar }) => navbar);
    const {defaultCameraIndexFromBackend } = useTypedSelector(({ media }) => media);
    
    const { 
        defaultSubcategory, 
        setDefaultMovingRoom, 
        selectRoom
    } = useTypedSelector(({ footernavbar }) => footernavbar);

    let roomCategoryIndex: number

    RoomCategory.map((room: any, roomIndex: number) => {
        return room.Category.Items?.map((category: any) => {
            if (defaultSubcategory === category) {
                roomCategoryIndex = roomIndex
            }
        })
    })

    const firstUpdate = useRef(true);

    const handleMouseOver = (room: string, roomIndex: number, isRoomSingle: string) => {
        setHoverRoomCategory(room)
        if (roomCategoryIndex === roomIndex) {
            setIsShow(true)
        }
        setIsOpen(true)
        handleActiveSubcategory(true, isRoomSingle)
    }

    const handleMouseLeave = () => {
        setIsOpen(false)
        setIsShow(false)
        handleActiveSubcategory(false)
    }

    const handleSubcategory = (subcategory: string) => {
        setIsOpen(false)
        setIsShow(false)
        handleActiveSubcategory('')
        DefaultSubcategory(subcategory)
        gettingTotalCameras(subcategory)
    }


    const handleRoomCategory = (roomName: string) => {
        retrieveRoomType(roomName)
        HandleRoomAction(roomName);
    }

    useEffect(() => {
        setIsOpen(true)
    }, [hoverRoomCategory])

    useEffect(() => {
        if(defaultCameraIndexFromBackend?.CameraIndex){
            setCameraIndex(defaultCameraIndexFromBackend.CameraIndex)
        }
    }, [defaultCameraIndexFromBackend])

    useEffect(() => {
        if (!firstUpdate.current) {
            if (setDefaultMovingRoom.Room == '') {
                DefaultSubcategory(setDefaultMovingRoom.Room)
            }
            // else {
            //     DefaultSubcategory(setDefaultMovingRoom.Room);
            // }
        }
        if (firstUpdate.current) {
            firstUpdate.current = false;
        }
    }, [setDefaultMovingRoom.Room])


    
    let isLoaded = true
    const dynamicWidth = isLoaded ? "" : '850px';

    return <>
    {helpButtonToggle && isSliderHover && 
                <HelpSection 
                  text="Directly access rooms" 
                  iconSpace="" 
                  className="slider_tooltip" 
                  icon={<HelpRightIcon />}
                />
              }
        <div onMouseOver={() => setIsSliderHover(true)} onMouseLeave={() => setIsSliderHover(false)} className={`pcView_slider ${dynamicWidth}`}>
            <ul>
                {RoomCategory.map((room: any, roomIndex: number) => {
                    const currentRoom = room.Category.Name
                    return <li
                        onClick={() => handleRoomCategory(currentRoom)}
                        onMouseOver={() => { handleMouseOver(currentRoom, roomIndex, room.Category.Type) }}
                        onMouseLeave={handleMouseLeave}
                        key={roomIndex}
                        data-value={currentRoom}
                        className={`${hoverRoomCategory === currentRoom && isOpen && room.Category.Type !== "Single" && 'active_PcView'}
                        ${selectRoom === currentRoom && 'activeSlider'}
                        ${room.Category.Type == 'Single' && currentRoom === selectRoom && 'activeSlider'}
                        `}
                    >
                        {!isShow && <>
                            {roomCategoryIndex === roomIndex && room.Category.Type !== 'Single' &&
                                <div
                                    style={{ top: defaultSubcategory.length > 16 ? -47 : -27, height: defaultSubcategory.length > 16 ? 46 : 26 }}
                                    className="selectItem"
                                >
                                    <p>{defaultSubcategory}</p>
                                </div>}
                        </>}
                        <SubCategoryDropdown
                            isOpen={isOpen}
                            room={room}
                            handleSubcategory={handleSubcategory}
                            roomCategoryIndex={roomCategoryIndex}
                            roomIndex={roomIndex}
                         />
                        <span>{room.Category.Name}</span>
                    </li>
                })}
            </ul>
        </div>
    </>
}


export default SliderComponent;