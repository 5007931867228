import React, { useState } from "react";
import styled from "styled-components";
import RoofViewSvgIcons from "../mobileLandscape/LeftSection/RoofViewSvgIcons";
import TimeZoneOpen from "../SvgIcons/TimeZone";
import CloseIcons from "../SvgIcons/Close";
import { useTypedSelector } from "../../hooks/use-typed-selector";

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.8); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: all 0.3s ease-in-out;

  &.zoomIn {
    opacity: 1;
  }

  &.zoomOut {
    opacity: 0;
  }
`;

const Popup = styled.div`
  animation: zoomIn 0.3s ease-in-out;
  @keyframes zoomIn {
    from {
      transform: scale(0.8);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  &.zoomOut {
    animation: zoomOut 0.3s ease-in-out forwards;
  }
  @keyframes zoomOut {
    from {
      transform: scale(1);
      opacity: 1;
    }
    to {
      transform: scale(0.8);
      opacity: 0;
    }
  }

  h2 {
    margin-top: 0;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 8px;
  right: 21px;
  background: none;
  border: none;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  z-index: 9;
`;

const Animation = ({ children }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const {current_updated_time:{Hours, Minutes}} = useTypedSelector(({themeMode}) => themeMode)
  // const [isHover, setIsHover] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div onClick={handleOpen}>
      <div className="BgDayNightMode">
      <div className="OpenButton">
       <TimeZoneOpen />
      </div>
      </div>
      <div className="ShowTime">
        <p>{Hours <= 9 ? "0" + Hours : Hours}:{Minutes === 0 ? Minutes + "0" : Minutes <= 9 ? "0" + Minutes : Minutes} <br />{Hours >= 12 ? "PM" : "AM"}</p>
      </div>
      {/* <button onClick={handleOpen}>Open1
        
      </button> */}
      {isOpen && (
        <PopupOverlay className={isOpen ? "zoomIn" : "zoomOut"}>
          <Popup className={isOpen ? "zoomIn" : "zoomOut"}>
            <CloseButton onClick={(e) => {handleClose(); e.stopPropagation()}}>
            <div className="CloseIcons">
                <CloseIcons />
            </div>
            </CloseButton>
            {children}
          </Popup>
        </PopupOverlay>
      )}
    </div>
  );
};

export default Animation;
