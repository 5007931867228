import { Component } from "../Types";
import React, { Fragment } from "react";
import ComponentIconMapData from "./ComponentIconMapData/ComponentIconMapData";

const AvailableSubComponent = ({
  items,
  isHover,
  componentItem,
  onComponentClick,
  componentBarOpen,
  availableComponent,
  onComponentHover,
  onComponentUnHover,
  selectedComponentRoom,
  currentStateOfComponent,
  isCurrentComponentHover,
}: any) => {
  const { componentIconMap } = ComponentIconMapData();

  if (availableComponent > 0) {
    return (
      <ul
        className={`${
          componentBarOpen ? "components-child2" : "components-child1"
        } ${availableComponent > 1 && "component_item_height"} ${
          availableComponent === 0 && "component_item"
        }`}
      >
        {componentItem?.RoomType.Components.map(
          (component: Component, index: number) => {
            const componentName = component.Component;
            const componentDisplayName = component.DisplayName;
            const category = component.Category;
            const iconComponent = componentIconMap[componentName];
            return (
              <Fragment key={index}>
                {category !== "Base" && (
                  <li
                    onMouseEnter={(e) =>
                      onComponentHover(componentName, index, e)
                    }
                    onMouseLeave={(e) =>
                      onComponentUnHover(componentName, index, e)
                    }
                    className={
                      currentStateOfComponent &&
                      selectedComponentRoom === componentName
                        ? "btm-drop-down active-bg "
                        : "btm-drop-down"
                    } // Removed two classes active-color-bg and active-bg-dropdown
                    onClick={(e) => {
                      onComponentClick(e, componentName);
                    }}
                    data-value={index}
                  >
                    <div className="similer-ic">
                      {React?.cloneElement(iconComponent, {
                        fill:
                          selectedComponentRoom === componentName ||
                          (isHover && index === isCurrentComponentHover)
                            ? "#3A3A3A"
                            : "white",
                      })}
                    </div>
                    {items.expand && <span>{componentDisplayName}</span>}
                  </li>
                )}
              </Fragment>
            );
          }
        )}
      </ul>
    );
  }
  return null;
};

export default AvailableSubComponent;
