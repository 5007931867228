// import { createStore , applyMiddleware } from 'redux';
// import thunk from 'redux-thunk';
// import { composeWithDevTools, devToolsEnhancer } from 'redux-devtools-extension';
// import reducer from './reducers';

// export const store = createStore(reducer , {} , composeWithDevTools( applyMiddleware(thunk), devToolsEnhancer({trace:true}) ));


import { createStore , applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from './reducers';

export const store = createStore(
    reducer , 
    {} , 
    composeWithDevTools({ trace: true})(
        applyMiddleware(thunk)
    )
);