import { useTypedSelector } from "../../hooks/use-typed-selector";

interface SubCategoryRoomType{
    isOpen:boolean,
    handleSubcategory:(subcategory:string) => void,
    room:{
        Category:{
            Items: string[],
            Name: string,
            Type: string
        },
    },
    roomCategoryIndex:number,
    roomIndex: number,
}

const SubCategoryDropdown = ({isOpen, room, handleSubcategory, roomCategoryIndex, roomIndex}:SubCategoryRoomType) => {
    const defaultSubcategory = useTypedSelector(({ footernavbar }) => footernavbar.defaultSubcategory);


    return <div className="dropdownslider_outer">
        {isOpen && room.Category.Type !== "Single" && <div className='dropdownSlider'>
            {room.Category.Items?.map((item: any, index: number) => {
                const roomCategoryItemLength = room.Category.Items.length
                return <p
                    key={index}
                    onClick={() => handleSubcategory(item)}
                    className={`${roomCategoryItemLength === 1 ? 'itemRadius' : 'dropdownslider1 active_sliderPc'}
                        ${isOpen && roomCategoryIndex === roomIndex && defaultSubcategory === item && 'active_parimary'}`}
                    >
                    {item}
                    </p>
                }
            )}
        </div>}
    </div>
}



export default SubCategoryDropdown;