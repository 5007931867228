function TimeIcons4({ width, height, fill, viewBox }:any) {
    return (
<svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.8883 14.7009C14.1923 16.8002 11.6633 18 8.95579 18C4.01666 18 0 13.9512 0 8.97284C0 4.35418 3.45145 0.485508 8.00368 0.00565877C8.18216 -0.0242563 8.36065 0.0656988 8.44989 0.24561C8.53914 0.395606 8.50946 0.605433 8.39033 0.755423C7.22994 2.19492 6.57537 4.02434 6.57537 5.88392V6.18391L5.68271 6.30378V5.85401C5.68271 4.14444 6.15881 2.49512 7.05148 1.05541C3.48112 1.92503 0.892513 5.13413 0.892513 8.94293C0.89272 13.4413 4.52265 17.1004 8.95623 17.1004C11.0389 17.1004 12.973 16.3207 14.4606 14.9111C11.9614 14.8812 9.67031 13.7714 8.09338 12.0621L8.92647 11.6123C10.3844 13.0819 12.4076 14.0116 14.6391 14.0116C14.9368 14.0116 15.2045 13.9817 15.5019 13.9515C15.6804 13.9216 15.8589 14.0116 15.9481 14.1915C16.0373 14.3413 16.0077 14.5513 15.8885 14.7011L15.8883 14.7009ZM7.70614 6.63347L8.65836 4.68419C8.7476 4.53419 8.89641 4.44424 9.04522 4.44424C9.19403 4.44424 9.37251 4.53419 9.43207 4.68419L10.3843 6.63347L12.5265 6.93346C12.705 6.96337 12.8242 7.08345 12.8835 7.23345C12.9431 7.38345 12.8835 7.56336 12.7646 7.68322L11.2767 9.24257L11.6337 11.4019C11.6634 11.5818 11.604 11.7318 11.4552 11.8517C11.366 11.9117 11.2767 11.9416 11.1875 11.9416C11.1279 11.9416 11.0387 11.9117 10.9791 11.8816L9.07487 10.862L7.17065 11.8816C7.02184 11.9715 6.84335 11.9416 6.69455 11.8517C6.54574 11.7617 6.48617 11.5818 6.51606 11.4019L6.87303 9.24257L5.32602 7.71311C5.20709 7.59324 5.14753 7.41312 5.20709 7.26333C5.26666 7.11334 5.38558 6.99347 5.56407 6.96334L7.70614 6.63347ZM8.06333 7.4732L6.57565 7.68324L7.64678 8.73302C7.7657 8.82297 7.79559 8.97297 7.7657 9.12296L7.49797 10.6225L8.83682 9.93276C8.89639 9.90284 8.98563 9.87272 9.0452 9.87272C9.10476 9.87272 9.194 9.90263 9.25357 9.93276L10.5924 10.6225L10.3247 9.12296C10.295 8.97296 10.3544 8.82297 10.4436 8.73302L11.5147 7.68324L10.0271 7.4732C9.87825 7.44329 9.75933 7.35333 9.69977 7.23325L9.04519 5.88373L8.39061 7.23325C8.36073 7.38325 8.21212 7.4732 8.06332 7.4732H8.06333Z" fill={fill}/>
</svg>
 );
}

export default TimeIcons4;

// 28 30 001618 