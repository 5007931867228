

function MySvgIcon({ width, height, fill, viewBox }:any) {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      <path d="M20.0202 4.5061C18.1213 4.5061 16.5762 6.051 16.5762 7.95017C16.5762 9.84911 18.1211 11.3942 20.0202 11.3942C21.9192 11.3942 23.4643 9.84934 23.4643 7.95017C23.4641 6.05123 21.9192 4.5061 20.0202 4.5061ZM20.0202 10.4284C18.6684 10.4284 17.5418 9.30177 17.5418 7.95C17.5418 6.59817 18.6685 5.47158 20.0202 5.47158C21.3721 5.47158 22.4987 6.59822 22.4987 7.95C22.4987 9.30183 21.4041 10.4284 20.0202 10.4284Z" fill={fill}/>
      <path d="M26.9079 0H2.05993C0.933292 0 0 0.933292 0 2.05993V26.9401C0 28.0667 0.901221 29 2.05993 29H26.9401C28.0667 29 29 28.0667 29 26.9401V2.05993C28.9679 0.933292 28.0346 0 26.908 0H26.9079ZM27.6481 26.9079C27.6481 27.3264 27.3262 27.6481 26.9079 27.6481H2.05993C1.64144 27.6481 1.3197 27.3262 1.3197 26.9079V18.2175C1.38414 18.1854 1.41624 18.121 1.48068 18.0566L7.91797 8.36843C8.07895 8.11091 8.30414 7.98226 8.52957 7.98226C8.755 7.98226 8.98017 8.11091 9.14117 8.36843L18.604 22.627C18.7326 22.8201 18.8936 22.9167 19.0546 22.9167C19.2156 22.9167 19.3765 22.8201 19.4731 22.6591L24.3332 15.7069C24.4942 15.4493 24.7194 15.3207 24.9448 15.3207C25.1702 15.3207 25.3954 15.4493 25.524 15.6427L27.6482 19.3119L27.6481 26.9079ZM27.6481 16.4473L26.425 14.3229C26.0388 13.7113 25.4917 13.3896 24.9443 13.3896C24.365 13.3896 23.85 13.7116 23.4636 14.3229L19.0223 20.6315L10.01 7.01659C9.62388 6.40499 9.07675 6.0833 8.49728 6.0833C7.91804 6.0833 7.403 6.40525 7.01659 7.01659L1.31976 15.6104V2.05988C1.31976 1.64138 1.64172 1.31964 2.05999 1.31964H26.9401C27.3586 1.31964 27.6804 1.6416 27.6804 2.05988L27.6806 16.4472L27.6481 16.4473Z" fill={fill}/>
    </svg>
  );
}

export default MySvgIcon;