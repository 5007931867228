import { ActionType } from "../action-types";
import { Action } from "../actions/mode";

interface ModeType{
    // selectedMode: string;
    changeCurrentMode:string
    timer:number
}

export const INITIAL_STATE: ModeType = {
    // selectedMode: 'Manual',
    changeCurrentMode: "Manual",
    timer: 0
}

export default (state: ModeType = INITIAL_STATE, action: Action): ModeType => {

    switch (action.type) {
        //LOADING EVENTS START HERE
        // case ActionType.MODE:
        //     return { ...state, selectedMode: action.payload }
        case ActionType.CURRENT_MODE_CHANGE:
            return { ...state, changeCurrentMode: action.payload }
        case ActionType.AUTO_MODE_TIMER:
            return { ...state, timer: action.payload }
        default:
            return state;
    }
};