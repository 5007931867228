import { isMobile } from "react-device-detect";
import { StateActionTypes } from "./ItemTypes";
import arrow_left from "../../assets/image/arrow-left.png";
import arrow_right from "../../assets/image/arrow-right.png";

const ItemListReducer = () => {

    const navbarReducer = (state:any, action:any) => {
        switch (action.type) {
            case StateActionTypes.LOAD_ROOM_TYPE:
                return {...state, currentItems:action.currentItems}
            case StateActionTypes.UPDATE_ROOM_TYPE:
                return {...state, currentActiveRoomType: action.activeRoomType}   
            case StateActionTypes.UPDATE_BUILDING_TYPE_STATE:
                return {...state, currentBuildingTypeState: action.activeBuildingState}
            case StateActionTypes.UPDATE_NAVBAR_STATE:
                return {...state, navbarState: action.currentNavbarState}
            case StateActionTypes.TOGGLE_COMPONENT:
                const expand = !state.expand;
                const width = expand ? (isMobile ? '178px' : '210px') : '57px';
                const arrow = expand ? arrow_left : arrow_right;
                return {...state, expand, width, arrow};
            case StateActionTypes.CLOSE_TOGGLE_COMPONENT:
                return {...state, expand: false, width: '57px', arrow: arrow_left};
            default:
                return state
        }
    };

    return {
        navbarReducer
    }
}


export default ItemListReducer;