import React, { useState } from "react";
import styled from "styled-components";
import { Webrtc } from "../../services/webrtc";

interface ButtonProps {
  disabled: boolean;
}

const UndoRedoButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const Button = styled.button<ButtonProps>`
  padding: 10px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 14px;
  background-color: ${(props) => (props.disabled ? "#ccc" : "#2a2a2a6e")};
  color: #fff;
  border: none;
  border-radius: 4px;
  outline: none;

  &:hover {
    background-color: ${(props) => (props.disabled ? "#ccc" : "#f9d412")};
    svg path {
      fill: #000000;
    }
  }
`;

interface HistoryControlComponentProps {
  // onUndo: () => void;
  // onRedo: () => void;
  canUndo: boolean;
  canRedo: boolean;
}

const HistoryControl: React.FC<HistoryControlComponentProps> = ({
  // onUndo,
  // onRedo,
  canUndo,
  canRedo,
}) => {
  const handleUndo = () => {
    if (canUndo) {
      Webrtc.undo();
    }
  };

  const handleRedo = () => {
    if (canRedo) {
      Webrtc.redo();
    }
  };
  return (
    <UndoRedoButtons>
      <Button onClick={handleUndo} disabled={!canUndo}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="m6.05 7.25 2.22-2.22A.75.75 0 0 0 7.2 3.97L4.43 6.75c-.69.68-.69 1.8 0 2.48l2.83 2.83A.75.75 0 0 0 8.32 11L6.07 8.75H16a4.25 4.25 0 1 1 0 8.5h-4a.75.75 0 1 0 0 1.5h4a5.75 5.75 0 0 0 0-11.5H6.05z"
          ></path>
        </svg>
      </Button>
      {/* <Button onClick={handleRedo} disabled={!canRedo}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="currentColor"
            d="m18.054 7.252-2.296-2.296a.75.75 0 0 1 1.06-1.06l2.83 2.828a1.75 1.75 0 0 1 0 2.475l-2.832 2.831a.75.75 0 0 1-1.06-1.06l2.219-2.22H8a4.25 4.25 0 0 0 0 8.5h4a.75.75 0 0 1 0 1.5H8a5.75 5.75 0 0 1 0-11.5h10c.018 0 .036 0 .054.002Z"
          ></path>
        </svg>
      </Button> */}
    </UndoRedoButtons>
  );
};

export default HistoryControl;
