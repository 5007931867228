import React, { Fragment, useState } from "react";
import { Component } from "../../Types";
import UseToggle from "../../UseToggle";
import leftArrow from "../../../assets/image/arrow-left.png";
import ComponentMenuHandler from "../../leftsection/ComponentMenuHandler";
import ComponentIconMapData from "../../leftsection/ComponentIconMapData/ComponentIconMapData";
import MobileComponentDownArrow from "../../SvgIcons/MobileSvgIcons/MobileComponentDownArrow";
import MobileComponentUpArrow from "../../SvgIcons/MobileSvgIcons/MobileComponentUpArrow";


const MobileComponent = ({isToggleOpen, handleUpdateToggleState, targetCurrentElementRef}:any) => {

    const { componentIconMap } = ComponentIconMapData();
    const [showName, setShowName] = useState(false)

    const {
        componentItem,
        availableComponent,
        selectedComponentRoom,
        onComponentClick
    } = ComponentMenuHandler();


    return (
        <div ref={targetCurrentElementRef} style={!showName ? { overflow: 'hidden' } : {}} className="compoments_leftSide components_content_outer step step-2">
            <div onClick={() => setShowName(prev => !prev)} className={`item_compoments compoments_active ${!availableComponent && 'single_components'} ${showName && "space_box"}`}>
                {!showName ? <img src="/images/svgIcons/RightArrow.svg" alt="img" /> : <img src={leftArrow} />}
                <p style={{display:`${showName ? "block" : "none"}`}}>Components</p>
            </div>
            <div className="yellow_box_outer">
            {isToggleOpen[1] && availableComponent ? (componentItem?.RoomType.Components.map((component: Component, index: any) => {
                const iconComponent = componentIconMap[component.Component]
                const category = component.Category;
                return (
                    <Fragment key={index}>
                        {category !== "Base" && <div
                            onClick={(e: any) => onComponentClick(e, component.Component)}
                            className={`${selectedComponentRoom === component.Component && 'activeMode_auto'} ${showName ? "active-color" : ''} item_compoments btm_components_section`}
                            data-value={index}
                        >
                            <div className="SvgIcon">
                                {React?.cloneElement(iconComponent, {
                                    fill: selectedComponentRoom === component.Component ? "#3A3A3A" : "white"
                                })}
                            </div>
                            <p style={{display:`${showName ? "block" : "none"}`}}>{component.DisplayName}</p>
                        </div>}
                    </Fragment>
                );
            }))
                : ('')}
            </div>
            <div onClick={() => handleUpdateToggleState(1)} className="closing-arrow">
                {!isToggleOpen[1] ? <MobileComponentDownArrow /> : <MobileComponentUpArrow />}
            </div>
        </div>
    );
}


export default MobileComponent;