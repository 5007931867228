import { useReducer } from "react";
import ItemListReducer from "./ItemListReducer";
const ItemListAction = (initialState:any) => {

    const {navbarReducer} = ItemListReducer();
    const [items, dispatch] = useReducer(navbarReducer, initialState);

    return {
        items,
        dispatch
    }
}


export default ItemListAction;