import {useState, useRef, useEffect} from "react";




const UseToggle = (initialState:Array<boolean>) => {

    const [isToggleOpen, setIsToggleOpen] = useState<any>(initialState);

    const targetCurrentElementRef = useRef<any>(null);

    const handleUpdateToggleState = (index:any) => {
        setIsToggleOpen((prev: any) => {
            let newStateValue = prev.map((item:any, i:number) => i === index ? !item : false);
            return newStateValue;
        })
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      });
    
      const handleClickOutside = (event:any) => {
        if (targetCurrentElementRef.current && !targetCurrentElementRef.current.contains(event.target)) {
            setIsToggleOpen((prev: any) => prev.map(() => false));
        }
      };

    return {
        isToggleOpen,
        targetCurrentElementRef,
        handleUpdateToggleState
    }
}


export default UseToggle;