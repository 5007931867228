import ReactDOM from "react-dom";
import styled from "styled-components";
import StartApplicationByPass from "../../StartWebApplicationByPass";

const MainModalDiv = styled.div`
    width: 100%;
    height: 100%;
    background: url('/images/bg_overlay.jpg');
    position: absolute;
    z-index: 999999 !IMPORTANT;
    top: 0px;
    left: 0px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .virtual_experience_overlay{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0px;
    left: 0px;
    }
`;

interface propType{
    children?:boolean|{}, 
    isPortrait?:{}, 
    handleStartEmulator?:()=>void, 
    className?:string,
    setIsMatched?:any,
    isMatched?:any,
    availableSlots?:any
}

const Modal = ({children, isPortrait, handleStartEmulator, className, setIsMatched, isMatched, availableSlots}:propType) => {

    if(isPortrait) return null;
    const rootElement: HTMLElement = document.getElementById("portals")!;

    return ReactDOM.createPortal(
        <MainModalDiv>
            <StartApplicationByPass availableSlots={availableSlots} setIsMatched={setIsMatched} isMatched={isMatched}/>
            <div className="virtual_experience_overlay"></div>
            {availableSlots !== 0  && 
                <button disabled={!isMatched} onClick={handleStartEmulator} className={className}>
                    {children}
                </button>
            }
        </MainModalDiv>,
       rootElement
    )
}


export default Modal


