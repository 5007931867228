

const HelpRightIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="15" viewBox="0 0 32 15" fill="none">
            <path d="M2.92594 9.48187C10.6315 13.0577 19.6092 12.177 26.5965 7.49255C26.2293 8.90245 26.0154 10.3389 25.8014 11.7753C25.7715 11.9303 27.1294 12.7739 27.2954 12.8987C27.4614 13.0235 28.764 13.8255 28.7894 14.0221C29.2861 10.1362 30.298 6.37144 31.9784 2.75443C32.0936 2.48608 28.9306 0.817567 29.0458 0.549216L29.0032 0.605901C28.8925 0.522685 28.7392 0.496154 28.7392 0.496154C25.5021 1.34529 22.1842 1.95624 18.9135 2.15893C19.2074 2.11369 20.1308 3.0743 20.4075 3.28234C20.6841 3.49038 21.4969 4.36777 21.9015 4.40575C23.145 4.36471 24.3758 4.22539 25.5939 3.98777C18.3418 9.71551 8.47801 11.0834 0.133895 7.20489C1.04913 7.71564 1.81478 9.00128 2.92594 9.48187Z" fill="white" />
        </svg>
    );
}


export default HelpRightIcon