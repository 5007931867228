import { Fragment } from "react";
import { BuildingList, BuildingViewType } from "../../../Types";


const NavbarTypeOfBuildingView = ({
    isToggleOpen,
    allBuildingData,
    activeBuildingType,
    handleBuildingActive
}: BuildingViewType) => {

    return (
        <Fragment>
            {isToggleOpen[0] && <><div className="type_buliding">
                <div className="type_bulidingItem">
                    <ul>
                        <li className="buliding_active1">Type of Buildings</li>
                    </ul>
                </div>
                <div className="flat_numbering">
                    <ul>
                        {allBuildingData.BuildingList.map((building: BuildingList, index: number) => {
                            return <li
                                key={index}
                                data-name={building.BuildingType.Name}
                                data-value={index}
                                onClick={(e:React.MouseEvent<HTMLLIElement>) => handleBuildingActive(e)}
                                className={`${activeBuildingType === building.BuildingType.Name && 'room_active'}`}
                            >
                                <img className="image_bluding" src="/images/svgIcons/1BHK_ic.svg" alt="img" />
                                {building.BuildingType.Name}
                            </li>
                        })}
                    </ul>
                </div>
            </div></>}
        </Fragment>
    );
}


export default NavbarTypeOfBuildingView;