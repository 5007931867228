import { SubComponentItems } from "./SubComponentItems";


interface ModalItemsType {
    className: {
        divSetVariations: string,
        divRow: string,
        divCol: string
    }
}

export const ModalItems = (props: ModalItemsType) => {
    return (
        <div className={props.className.divSetVariations}>
            <div className={props.className.divRow}>
                <div className={props.className.divCol}>
                    <SubComponentItems />
                </div>
            </div>
        </div>
    );
}