import { isMobile } from "react-device-detect";
import arrowLeft from "../../assets/image/arrow-left.png";
import arrowRight from "../../assets/image/arrow-right.png";
import SliderComponent from "./SliderComponent";
import HelpSection from "../Common/HelpSection";
import HelpRightIcon from "../SvgIcons/HelpRightIcon";
import { useState } from "react";

const CameraIndexBar = (props:any) => {

  const [isHover, setIsHover] = useState(false);

  const {
    isOpen,
    activeCamera,
    isFullScreen,
    onCameraSelect,
    gettingTotalCameras,
    setDefaultMovingRoom,
    currentActiveRoomtype, 
    handleNextArrowButton,
    handleActiveSubcategory,
    numberOfCamerasAvailable, 
    handlePreviewsArrowButton,
    helpButtonToggle 
  } = props
    return (
      <div
        className={`middle-class ${isMobile && 'mobile_landscape_cameraView'} ${isFullScreen && "onExpandContentMoving"}`} id="pagination"
      >   
        { <>
          {helpButtonToggle && isHover && <HelpSection text="Adjust viewing angles" iconSpace="" className="camera_index_tooltip" icon={<HelpRightIcon />}/>}
          {currentActiveRoomtype !== "Empty" && setDefaultMovingRoom !== "" && !isOpen &&<>
              {numberOfCamerasAvailable?.length ? (
                <div 
                  onMouseOver={() => setIsHover(true)}
                  onMouseLeave={() => setIsHover(false)}
                  className="middle-sec-numbering step step-8"
                >
                  <button onClick={handlePreviewsArrowButton} className="middle-class-numbering-left"><img src={arrowLeft} /></button>
                  <button onClick={handleNextArrowButton} className="middle-class-numbering-right"><img src={arrowRight} /></button>
                  <ul className="slider-numbering">
                    {numberOfCamerasAvailable?.map((camera:any, index:number) => {
                      return <li key={index} data-cam={camera.CameraIndex} onClick={onCameraSelect} className={activeCamera === camera.CameraIndex ? 'item-numbering active_numbering' : 'item-numbering'}></li>
                    }
                    )}
                  </ul>
                </div>
              ) : ('')}
            </>}
            </>}
            {!isMobile && <div className="slider-child-outer step step-9">
              <SliderComponent 
                gettingTotalCameras={gettingTotalCameras} 
                handleActiveSubcategory={handleActiveSubcategory}
                helpButtonToggle={helpButtonToggle}
              />
            </div>
            }
        </div>
    ); 
}



export default CameraIndexBar;