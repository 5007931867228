import { ActionType } from "../action-types";
import { BuildingListType } from "../../type/BuildingData";

const initialState:BuildingListType = {
  BuildingList:[],
};

function buildingReducer(state = initialState, action:any) {
  switch (action.type) {
    case ActionType.LOAD_BUILDING_DATA:
      return {
        ...state,
        BuildingList: action.payload
      };
    default:
      return state;
  }
}

export default buildingReducer;