import { useState } from "react";
import UseToggle from "../UseToggle";
import AutoSvgIcons from "./AutoSvgIcons";
import { useActions } from "../../hooks/use-actions";
import {autoModeRequest} from "../../services/media";
import ModeToggleSvgIcons from "./ModeToggleSvgIcons";
import ToggleArrowSvgIcons from "./ToggleArrowSvgIcons";
import { useTypedSelector } from "../../hooks/use-typed-selector";
import HelpSection from "../Common/HelpSection";
import HelpRightIcon from "../SvgIcons/HelpRightIcon";

export const AutoToggle = ({isFullScreen, helpButtonToggle}:any) => {

  const { CurrentModeChangeAction } = useActions();
  const { changeCurrentMode } = useTypedSelector(({ mode }) => mode)
  const [isActive, setIsActive] = useState(false)


  const {
    isToggleOpen,
    targetCurrentElementRef,
    handleUpdateToggleState
  } = UseToggle([false]);

  const [isHover, setIsHover] = useState(false);
  const [isHoverSvgIcons, setIsHoverSvgIcons] = useState(false);
  const [hoverAutoSvgIcons, setHoverAutoSvgIcons] = useState(false);

  const handleModeChange = (id: any) => {
    handleUpdateToggleState(0);
    CurrentModeChangeAction(id);
    if(id === "Auto"){
      autoModeRequest(id)
    }
  }

  return (
    <div className="auto_modeAll" style={{userSelect:"none"}} ref={targetCurrentElementRef}>
      <div
        onClick={() => {handleUpdateToggleState(0); setIsActive(prev => !prev)}}
        className={`mode_outer step step-6 ${isActive && 'mode_active'} ${isFullScreen && "onExpandContentMoving"}`}
        onMouseOver={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <p>{changeCurrentMode} {
          <span className="centerModeArrow">
            <ToggleArrowSvgIcons
              rotation={!isToggleOpen[0] ? "M2 8L8 2L14 8" : "M14 2L8 8L2 2"}
              width="16"
              height="10"
              viewBox="0 0 16 10"
              fill={isHover || isActive ? "#3A3A3A" : "white"}
            />
          </span>
        }
        </p>
      </div>
      {isToggleOpen[0] && <>
        <div className="outer_modeItem">
          <div
            id="Manual"
            onClick={(e) => { handleModeChange(e.currentTarget.id) }}
            className={`itemMode ${changeCurrentMode == "Manual" && "itemMode_active"}`}
            onMouseOver={() => setIsHoverSvgIcons(true)}
            onMouseLeave={() => setIsHoverSvgIcons(false)}
          >
            <p>
              <span className="svgRotation">
                <ModeToggleSvgIcons
                  width={"17"}
                  height="24"
                  viewBox={`0 0 17 24`}
                  fill={isHoverSvgIcons || changeCurrentMode === "Manual" ? "#3A3A3A" : "white"}
                />
              </span>
              Manual
            </p>
          </div>
          {/* <div
            id="Auto"
            onClick={(e) => { handleModeChange(e.currentTarget.id) }}
            className={`itemMode ${changeCurrentMode == "Auto" && "itemMode_active"}`}
            onMouseOver={() => setHoverAutoSvgIcons(true)}
            onMouseLeave={() => setHoverAutoSvgIcons(false)}
          >
            <p>
              <span className="svgRotation">
                <AutoSvgIcons
                  width="23"
                  height="24"
                  viewBox="0 0 23 24"
                  fill={changeCurrentMode === "Auto" || hoverAutoSvgIcons ? "#3A3A3A" : "white"}
                />
              </span>
              Auto</p>
          </div> */}
        </div>
      </>}
        {isHover && helpButtonToggle && 
          <HelpSection 
            text="Tour Switch (unavailable)" 
            iconSpace="" 
            className="autoToggle_tooltip" 
            icon={<HelpRightIcon />}
          />
        }
    </div>

  );
};