import { ActionType } from "../action-types"




export const isElementHover = (hover:boolean | null) => {
    return {
        type: ActionType.MAIN_SCREEN_SUCCESS,
        payload: hover
    }
}