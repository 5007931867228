import BedSheetSvgIcons from "../../SvgIcons/BedSheetSvgIcons";
import CarpetSvgIcons from "../../SvgIcons/CarpetSvgIcons";
import CoffeeTableSvgIcons from "../../SvgIcons/CoffeeTableSvgIcons";
import CurtainsSvgIcons from "../../SvgIcons/CurtainsSvgIcons";
import KitchenChairSvgIcons from "../../SvgIcons/KitchenChairSvgIcons";
import KitchenTableSvgIcons from "../../SvgIcons/KitchenTableSvgIcons";
import LampSvgIcons from "../../SvgIcons/LampSvgIcons";
import SofaChairSvgIcons from "../../SvgIcons/SofaChairSvgIcons";
import SofaSvgIcons from "../../SvgIcons/SofaSvgIcons";

const ComponentIconMapData = () => {
  // Here Adding Component Svg Icons Which are dynamically change their color on Hover Over Mouse
  const componentIconMap: any = {
    BedSheet: <BedSheetSvgIcons width="28" height="35" viewBox="0 0 28 35" />,
    LivingRoomSofa: <SofaSvgIcons width="28" height="26" viewBox="0 0 28 26" />,
    LivingRoomSofaChair: (
      <SofaChairSvgIcons width="28" height="28" viewBox="0 0 28 28" />
    ),
    LivingRoomCoffeeTable: (
      <CoffeeTableSvgIcons width="23" height="22" viewBox="0 0 28 22" />
    ),
    KitchenTable: (
      <KitchenTableSvgIcons width="28" height="26" viewBox="0 0 28 26" />
    ),
    LivingRoomCurtains: (
      <CurtainsSvgIcons width="27" height="27" viewBox="0 0 27 27" />
    ),
    KitchenChair: (
      <KitchenChairSvgIcons width="27" height="27" viewBox="0 0 27 27" />
    ),
    BedRoomCarpet: (
      <CarpetSvgIcons width="27" height="27" viewBox="0 0 27 27" />
    ),
    ChangeableComponent: (
      <LampSvgIcons width="30" height="38" viewBox="0 0 30 38" />
    ),
  };

  return {componentIconMap};
};

export default ComponentIconMapData;
