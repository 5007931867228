

function FullScreenSvgIcons({ width, height, fill, viewBox, rotation }:any) {
    return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
        <path d="M28.9999 12.8827V2H18.1172" stroke={fill} strokeWidth="2.95918" strokeMiterlimit="10"/>
        <path d="M28.9999 18.1174V29.0002H18.1172" stroke={fill} strokeWidth="2.95918" strokeMiterlimit="10"/>
        <path d="M2 12.883V2.00024H12.8827" stroke={fill} strokeWidth="2.95918" strokeMiterlimit="10"/>
        <path d="M2 18.1174V29.0002H12.8827" stroke={fill} strokeWidth="2.95918" strokeMiterlimit="10"/>
    </svg>
    );
  }
  
  export default FullScreenSvgIcons;