import { ActionType } from "../action-types";
import { Action } from "../actions/mode";

interface CurrentTimeType {
    Hours: number,
	Minutes: number
}
interface ModeType{
    themeModeData: any
    current_updated_time: CurrentTimeType
}

export const INITIAL_STATE: ModeType = {
    themeModeData: '',
    current_updated_time: {Hours: 8, Minutes: 26}
}

export default (state: ModeType = INITIAL_STATE, action: Action): ModeType => {

    switch (action.type) {
        //LOADING EVENTS START HERE
        case ActionType.THEME_MODE:
            return { ...state, themeModeData: action.payload }
        case ActionType.UPDATE_TIME:
            return { ...state, current_updated_time: action.payload }
        default:
            return state;
    }
};