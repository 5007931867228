import { useState } from "react";
import { cameraRotation } from "../../services/navbarServices";
import CameraRotationSvgIcons from "../mobileLandscape/LeftSection/CameraRotationSvgIcons";


const CameraRotationView = () => {

    const [hover, setHover] = useState(false);
    const [active, setActive] = useState(false);

    const handleRotateCamera = (input: number) => {
        cameraRotation(input)
    }

    return (
        <div className="btn_90">
            <div onMouseOver={() => setActive(true)} onMouseLeave={() => setActive(false)} onClick={() => handleRotateCamera(90)} className={`btn_90Item`}>
                <CameraRotationSvgIcons 
                    width="31"
                    height="24"
                    viewBox="0 0 31 24"
                    fill={active ? "#3A3A3A" : "white"}
                />
            </div>
            <div onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={() => handleRotateCamera(-90)} className="btn_90Item">
                <CameraRotationSvgIcons 
                    width="31"
                    height="24"
                    viewBox="0 0 31 24"
                    fill={hover ? "#3A3A3A" : "white"}
                />
            </div>
        </div>
    );
}




export default CameraRotationView;