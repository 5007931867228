import { Fragment, useEffect, useState } from "react";
import { useActions } from "../../hooks/use-actions";
import { useTypedSelector } from "../../hooks/use-typed-selector";
import { roomTypeSelect } from "../../services/navbarServices";
import ItemListAction from "../ComponentStateService/ItemListAction";
import { StateActionTypes } from "../ComponentStateService/ItemTypes";
import { RoomType2 } from "../Types";
import HelpSection from "../Common/HelpSection";
import HelpRightIcon from "../SvgIcons/HelpRightIcon";


const initialState = {
    navbarState: '',
    currentItems: [],
    currentActiveRoomType: '',
    currentBuildingTypeState: '',
}


const TypeOfRoom = ({helpButtonToggle, items}:any) => {

    const [hoverOnRoomType, setHoverOnRoomType] = useState(false);
    const { dispatch } = ItemListAction(initialState);
    
    const { updateAvailableComponent, setSelectedRoomTypeIndex, handleGetStateData } = useActions();
    
    const { selectRoom, availableComponent } = useTypedSelector(({ footernavbar }) => footernavbar);
    const {
        buildingnavData: {
            BuildingType: {
                DefaultRoom
            }
        }
    } = useTypedSelector(({ navbar }) => navbar);

    const [componentActiveRoomType, setComponentActiveRoomType] = useState(items.currentActiveRoomType);

    const handleRoomName = (name: string, componentLength: number, index: any) => {
        const selectedRoom = selectRoom != "" ? selectRoom : DefaultRoom;
        if (name) {
            dispatch({ type: StateActionTypes.UPDATE_ROOM_TYPE, activeRoomType: name })
            roomTypeSelect(selectedRoom, name);
        }
        console.log('checking....', name)
        setComponentActiveRoomType(name);
        updateAvailableComponent(componentLength as any)
        setSelectedRoomTypeIndex(parseInt(index as string));
        handleGetStateData(name, selectedRoom)
    }

    useEffect(() => {
        if(items.currentActiveRoomType) {
            setComponentActiveRoomType(items.currentActiveRoomType)
        }
    }, [items])

    return (
        <>
            {helpButtonToggle && hoverOnRoomType && <HelpSection text="Change room types" iconSpace="" className="furnished_tooltip" icon={<HelpRightIcon />}/>}
            <div 
                className={`room_category step step-3 ${availableComponent === 3 && 'furnished_option'}`}
                onMouseOver={() => {setHoverOnRoomType(true)}} 
                onMouseLeave={() => {setHoverOnRoomType(false)}} 
            >
                {items?.currentItems?.Room?.RoomTypes.map((roomsType: RoomType2, index: number) => {
                    const roomTypeName = roomsType.RoomType.Name;
                    const roomTypeImage = items?.currentItems.Room.Name + " " + roomTypeName
                    return (
                        <Fragment key={index}>
                            <div  
                                className={`furnished-category category-inner ${componentActiveRoomType === roomTypeName && 'active'}`}
                                onClick={() => handleRoomName(roomTypeName, roomsType.RoomType.Components.length, index)}
                            >
                                <button className="btn">{roomTypeName}</button>
                                <div className="Furnished_image">
                                    <img src={`../../../images/${roomTypeImage}.png`} alt="image" />
                                </div>
                                {/* hoverOnRoomType  && hoverRoomType === index + 1 &&  */}
                            </div>
                        </Fragment>
                    );
                })}
            </div>
        </>
    );
}


export default TypeOfRoom;