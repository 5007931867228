import { useState } from "react";
import Tour from "reactour";
import styled from "styled-components";



const HelpSectionTooltip = ({setStartHelpTutorial, steps, handleDisableMouseInteraction}:any) => {
  const lastIndex = steps.length - 1;
  const [currentStep, setCurrentStep] = useState(0);

  const handleNext = () => {
    if (currentStep < lastIndex) {
      setCurrentStep((prevStep) => prevStep + 1);
    } else {
      // If on the last step, close the tour
      setStartHelpTutorial(false);
      handleDisableMouseInteraction(false)
    }
  };

  const handlePrev = () => {
    if (currentStep > 0) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  
    return (
        <TourContainer
            steps={steps}
            isOpen={true}
            onRequestClose={() => {setStartHelpTutorial(false); handleDisableMouseInteraction(false)}}
            rounded={15}
            nextButton={<HandleNextButton handleNextClick={handleNext} currentStep={currentStep} lastIndex={lastIndex}/>}
            prevButton={<HandlePrevButton handlePrevClick={handlePrev}/>}
            showNavigationNumber={false}
            showNavigation={false}
            accentColor={'#F9D412'}
            closeWithMask={false}
        />
    );
}



export default HelpSectionTooltip;

const HandleNextButton = ({handleNextClick, currentStep, lastIndex}:any) => {
  return <NextButton onClick={handleNextClick}>{currentStep === lastIndex ? 'Finish' : 'Next'}</NextButton>
}
  
  const HandlePrevButton = ({handlePrevClick}:any) => {
    return <PrevButton onClick={handlePrevClick}>Previous</PrevButton>
  }
  


  const NextButton = styled.button`
  background-color: rgba(255, 206, 70, 0.7);
  color: #3A3A3A;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #F9D412;
    color: #3A3A3A
  }
`;

const PrevButton = styled.button`
  background-color: rgba(255, 206, 70, 0.7);
  color: #3A3A3A;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #F9D412;
    color: #3A3A3A
  }
`;


const TourContainer = styled(Tour)`
  background-color: rgb(42 42 42 / 90%);
  color:white;
  max-width: 291px;
  // height: 150px;
`;