import React, { Fragment, useState } from "react";
import MobileComponentDownArrow from "../../SvgIcons/MobileSvgIcons/MobileComponentDownArrow";
import MobileComponentUpArrow from "../../SvgIcons/MobileSvgIcons/MobileComponentUpArrow";
import FortLogoMobile from "../../SvgIcons/MobileSvgIcons/FortLogoMobile";
import ComponentIconMapData from "../../leftsection/ComponentIconMapData/ComponentIconMapData";
import ComponentMenuHandler from "../../leftsection/ComponentMenuHandler";
import { Component } from "../../Types";
import UseToggle from "../../UseToggle";
import BlinkEffect from "../../Common/BlinkEffect";
import ToolTextPermissionBox from "../../Common/ToolTextPermissionBox";

const LeftSideMobileContent = ({ isToggleOpen, handleUpdateToggleState, showMobileHelpSection, setStartMobileHelpTutorial, setShowMobileHelpSection, isLoading, handleDisableMouseInteraction }: any) => {

    const { componentIconMap } = ComponentIconMapData();

    const {
        componentItem,
        availableComponent,
        selectedComponentRoom,
        onComponentClick
    } = ComponentMenuHandler();

    // const {
        //     isToggleOpen,
        //     targetCurrentElementRef,
        //     handleUpdateToggleState
        // } = UseToggle([false]);
        
        return (
            <>
            
                    <div className="Main-Top-Toggle step step-1">
            {!isLoading && showMobileHelpSection && <ToolTextPermissionBox handleDisableMouseInteraction={handleDisableMouseInteraction} setStartHelpTutorial={setStartMobileHelpTutorial} setShowHelpSection={setShowMobileHelpSection}/>}
                <BlinkEffect showHelpSection={showMobileHelpSection}>
                        <div className="Fortis-logo-section">
                            <FortLogoMobile />
                        </div>
                        <ul>
                            {isToggleOpen[0] &&
                                <><li>
                                    {isToggleOpen[0] && availableComponent ? (componentItem?.RoomType.Components.map((component: Component, index: number) => {
                                        const iconComponent = componentIconMap[component.Component]
                                        const category = component.Category;
                                        return (
                                            <Fragment key={index}>
                                                {category === "Base" && <div
                                                    onClick={(e: any) => onComponentClick(e, component.Component)}
                                                    key={index}
                                                    className={`${selectedComponentRoom === component.Component && 'activeMode_auto'} item_compoments btm_components_section`}
                                                    data-value={index}
                                                >
                                                    <div className="SvgIcon">
                                                        {React?.cloneElement(iconComponent, {
                                                            fill: selectedComponentRoom === component.Component ? "#3A3A3A" : "white"
                                                        })}
                                                    </div>
                                                </div>}
                                            </Fragment>
                                        );
                                    }))
                                        : ('')}

                                </li>
                                </>
                            }
                        </ul>
                        <div onClick={() => handleUpdateToggleState(0)} className="closing-arrow">
                            {!isToggleOpen[0] ? <MobileComponentDownArrow /> : <MobileComponentUpArrow />}
                        </div>
                </BlinkEffect>
                    </div>
        </>
    );
}

export default LeftSideMobileContent;