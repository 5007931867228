interface defaultSubcategorytype{
  defaultSubcategory:string,
  roomCategory:string
}

const ActiveMobileSubcategory = (props:defaultSubcategorytype) => {
    const {defaultSubcategory} = props

    return <div style={{top: defaultSubcategory.length < 14 ? -20 : -35}} className="selectMobileItem">
    <p>{defaultSubcategory}</p>
  </div>
}



export default ActiveMobileSubcategory;