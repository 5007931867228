import { ActionType } from "../state/action-types";
import { store as reduxStore } from "../state";
import { Webrtc } from "./webrtc";
import {
  componentSelection,
  componentSwitchMode,
  componentUnSelection,
  componentChangeableMode,
} from "./componentServices";

console.log(window.location.pathname);

//Just for testing, use real routes later on
var target: null | string = null;
if (window.location.pathname.startsWith("/t/")) {
  target = window.location.pathname.substring(3);
}

export function connectToWebRTC() {
  reduxStore.dispatch({
    type: "is_web_page_load",
    payload: true,
  });

  Webrtc.connect(
    "", //Signaling server address, empty string means same server
    target as string, //Target ID
    (uuid: string) => {
      console.log("Connected!", uuid);
    }, //Connected event
    (data: string) => {
      {
        handleResponse(data);
        console.log(data);
      }
    }, //Received data from server event  console.log("Received: " + data);
    (error: string) => {
      console.log(error);
    } //Error event
  );
}

const {
  mode: { changeCurrentMode },
} = reduxStore.getState();

const isAutoMode = changeCurrentMode === "Auto";

const handleResponse = (info: any) => {
  const serverData = JSON.parse(info);
  if ("EtoW_Input" in serverData && serverData["EtoW_Input"] === "Compass") {
    reduxStore.dispatch({
      type: ActionType.COMPASS,
      payload: serverData["Compass_Input"]["ViewAngle"],
    });
  } else if (serverData["EtoW_Input"] === "Camera") {
    reduxStore.dispatch({
      type: ActionType.DEFAULT_CAMERA_INDEX_FROM_BACKEND,
      payload: serverData["Camera_Input"],
    });
    if (serverData["Camera_Input"]) {
      reduxStore.dispatch({
        type: ActionType.DEFAULT_CAMERA_INDEX,
        payload: serverData["Camera_Input"]["CameraIndex"],
      });
    }
  } else if (
    "EtoW_Input" in serverData &&
    serverData["EtoW_Input"] === "Component"
  ) {
    reduxStore.dispatch({
      type: ActionType.DEFAULT_COMPONENT,
      payload: serverData["Component_Input"],
    });
    reduxStore.dispatch({
      type: ActionType.CURRENT_ACTIVE_COMPONENT,
      payload: serverData["Component_Input"],
    });
    reduxStore.dispatch({
      type: ActionType.DEFAULT_ACTIVE_COMPONENT,
      payload: serverData["Component_Input"]["Component"],
    });
    activeComponentItem(serverData["Component_Input"]);
    const componentType = serverData["Component_Input"]["Type"];
    const componentName = serverData["Component_Input"]["Component"];
    const componentCurrentOption = serverData["Component_Input"]["Option"];
    const switchMode = serverData["Component_Input"]["Switch_Input"];
    if (componentType === "Unselected") {
      reduxStore.dispatch({
        type: ActionType.SELECTED_COMPONENT_ITEM,
        payload: "",
      });
    }
    if (isAutoMode) {
      if (componentType === "Selected") {
        componentSelection(componentType);
      } else {
        componentUnSelection(componentType);
        reduxStore.dispatch({
          type: ActionType.SELECTED_COMPONENT_ITEM,
          payload: "",
        });
      }
    }
    if (componentType === "Switch" && isAutoMode) {
      componentSwitchMode(componentName, switchMode);
    }
    if (componentType === "Change" && isAutoMode) {
      componentChangeableMode(componentName, componentCurrentOption);
    }
  } else if (serverData["EtoW_Input"] === "Room") {
    reduxStore.dispatch({
      type: ActionType.SET_DEFAULT_MOVING_ROOM,
      payload: serverData["Room_Input"],
    });
    if (isAutoMode) {
      reduxStore.dispatch({
        type: ActionType.ROOM,
        payload: serverData["Room_Input"],
      });
    }
  } else if (serverData["EtoW_Input"] === "RoofView") {
    reduxStore.dispatch({
      type: ActionType.DEFAULT_ROOF_VIEW,
      payload: serverData["RoofView_Input"],
    });
  } else if (serverData["EtoW_Input"] === "AutoViewer") {
    reduxStore.dispatch({
      type: ActionType.AUTO_MODE_TIMER,
      payload: serverData["AutoViewer_Input"]["Timer"],
    });
  } else if (serverData["EtoW_Input"] === "Time") {
    reduxStore.dispatch({
      type: ActionType.UPDATE_TIME,
      payload: {
        Hours: serverData["Time_Input"]["Hours"],
        Minutes: serverData["Time_Input"]["Minutes"],
      },
    });
  }
  if (serverData) {
    reduxStore.dispatch({
      type: "is_web_page_load",
      payload: false,
    });
  }
};

export function setDefaultCameraFromBackend(cameraInput: { CameraIndex: any }) {
  const { CameraIndex } = cameraInput;
  reduxStore.dispatch({
    type: ActionType.DEFAULT_CAMERA_INDEX_FROM_BACKEND,
    payload: CameraIndex,
  });
}

export function activeComponentItem(Component: { Option: string }) {
  const { Option } = Component;
  reduxStore.dispatch({
    type: ActionType.CURRENT_ACTIVE_COMPONENT,
    payload: Option,
  });
}

export function DefaultActiveComponent(Component: { Component: any }) {
  reduxStore.dispatch({
    type: ActionType.DEFAULT_ACTIVE_COMPONENT,
    payload: Component.Component,
  });
}

export const buildingTypeSelect = (name: string, room: string) => {
  const buildingTypeData = {
    C_Type: "WtoU",
    WtoU_Input: "BuildingTypeSelect",
    BuildingTypeSelect_Input: {
      Name: name,
      Room: room,
    },
  };
  if (
    buildingTypeData.BuildingTypeSelect_Input.Name != " " &&
    buildingTypeData.BuildingTypeSelect_Input.Room != " "
  ) {
    console.log(buildingTypeData);
  }
  Webrtc.sendJSON(buildingTypeData);
};

export const roomTypeSelect = (roomName: string, roomType?: string) => {
  const roomTypeData = {
    C_Type: "WtoE",
    WtoE_Input: "RoomType",
    RoomType_Input: {
      Room: roomName,
      RoomType: roomType,
    },
  };
  console.log("11111111", roomTypeData);
  Webrtc.sendJSON(roomTypeData);
};

export const selectCurrentRoom = (
  currentRoomName: string,
  roomType?: string,
  roofViewState?: string
) => {
  // Here you can pass the condition if the roofView not active then first statement run
  if (roofViewState === "Active") {
    const roomTypeData = {
      C_Type: "WtoE",
      WtoE_Input: "Room",
      Room_Input: {
        Room: currentRoomName,
      },
    };
    console.log("roomTypeData", roomTypeData);
    Webrtc.sendJSON(roomTypeData);
  } else {
    const roomTypeData = {
      C_Type: "WtoE",
      WtoE_Input: "Room",
      Room_Input: {
        Room: currentRoomName,
        RoomType: roomType,
      },
    };
    console.log("roomTypeData", roomTypeData);
    Webrtc.sendJSON(roomTypeData);
  }
};

export const roofViewMode = (mode: any) => {
  const roofView = {
    C_Type: "WtoE",
    WtoE_Input: "RoofView",
    RoofView_Input: {
      State: mode ? "Off" : "On",
    },
  };
  Webrtc.sendJSON(roofView);
  console.log("**********", roofView);
};

export const cameraRotation = (input: number) => {
  const rotateCamera = {
    C_Type: "WtoE",
    WtoE_Input: "RoofView",
    RoofView_Input: {
      State: "Rotation",
      Rotation_Input: input,
    },
  };
  console.log("--------", rotateCamera);
  Webrtc.sendJSON(rotateCamera);
};

export const DollHouseView = (value: boolean) => {
  const dollView = {
    C_Type: "WtoE",
    WtoE_Input: "DollhouseView",
    DollhouseView_Input: {
      State: value ? "Off" : "On",
    },
  };
  console.log("--------", dollView);
  Webrtc.sendJSON(dollView);
};

export const captureWindowScreenshot = (
  resolutionX: number,
  resolutionY: number
) => {
  const screenShowInput = {
    C_Type: "WtoE",
    WtoE_Input: "Screenshot",
    Screenshot_Input: {
      ResX: resolutionX,
      ResY: resolutionY,
    },
  };
  console.log("--------screenShowInput", screenShowInput);
  Webrtc.sendJSON(screenShowInput);
};
