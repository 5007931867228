import screenfull from "screenfull";
import { useActions } from "../hooks/use-actions";
import NavbarHandler from "./leftsection/NavbarHandler";
import { useTypedSelector } from "../hooks/use-typed-selector";
import { StateActionTypes } from "./ComponentStateService/ItemTypes";
import { useEffect, useState, useLayoutEffect, useCallback } from "react";


const HomeComponentHandler = () => {

    const { dispatch } = NavbarHandler();
    const [isShow, setIsShow] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState<any>(false);
    const { setDefaultMovingRoom } = useTypedSelector(({ footernavbar }:any) => footernavbar);

    const {
        setCameraIndex,
        HandleRoomAction,
        HandleOnClickBuildingDataAction
    } = useActions();

    useEffect(() => {
        HandleOnClickBuildingDataAction("0");
    }, []);

    useLayoutEffect(() => {
        // Here Updating Room and CameraIndex Initialy When backend sends
        if(setDefaultMovingRoom.Room !== "None") { // When app load the setDefaultMovingRoom.Room comes None
            HandleRoomAction(setDefaultMovingRoom.Room)
            setCameraIndex(setDefaultMovingRoom.CameraIndex)
            dispatch({ type: StateActionTypes.UPDATE_ROOM_TYPE, activeRoomType: setDefaultMovingRoom.Type })
        }
    }, [setDefaultMovingRoom])

    const handleClickFullScreen = useCallback((e) => {
        if (screenfull.isEnabled) {
            screenfull.toggle()
        }
        setIsFullScreen((prev:any) => !prev);
    }, [])

    const exitFullScreenOnEsc = useCallback(() => {
        document.addEventListener("fullscreenchange", function () {
            if (!document.fullscreenElement) {
                setIsFullScreen(false)
            }
        });
    }, [])

    useEffect(() => {
        exitFullScreenOnEsc()
        return (() => exitFullScreenOnEsc())
    }, [exitFullScreenOnEsc])

    const handleShowContent = useCallback(() => {
        setIsShow(prev => !prev)
    }, [])

    return {
        isShow,
        isFullScreen,
        handleShowContent,
        handleClickFullScreen,
        setIsFullScreen
    }
}



export default HomeComponentHandler;