import { Dispatch } from "redux";
import { Action } from "../actions/navbar";
import {store as reduxStore} from "../store";
import { ActionType } from '../action-types';
import BuildingData1 from "../../BuildingData1.json"
import newBuildingData from "../../BuildingData.json"
import { buildingTypeSelect } from "../../services/navbarServices";
import { roomSelectedWithCamera } from "../../services/footernavbarServices";
import {setSelectedRoomIndex, setSelectedRoomTypeIndex} from "../action-creators/FooterNavbarAction";

import { BuildingType } from '../../type/NavbarType';
import { isMobile } from "react-device-detect";

export const HandleOnClickBuildingDataAction = (buildingvalue: string | null) => {
    return async (dispatch: Dispatch<Action>) => { 
        if (buildingvalue) {
            let isDataLoaded = true
            const currentBuildingData = isDataLoaded ? BuildingData1 : newBuildingData
            const selectBuilding:any = currentBuildingData.BuildingList[parseInt(buildingvalue)];
            dispatch({
                type: ActionType.BUILDING_DATA,
                payload: selectBuilding
            })
        }
    }
}

export const handleGetStateData = (defaultRoomtype:any, selectedRoom:any) => {
    return {
        type: ActionType.GET_STATE_RESPONSE,
        payload: {
            defaultRoomtype: defaultRoomtype,
            selectedRoom: selectedRoom
        }
    }
}

function getDefaultRoomValues(DefaultData: BuildingType) {
    const defaultRoom = DefaultData.BuildingType.DefaultRoom;
    let defaultRoomIndex = 0;
    let defaultRoomtypeIndex = 0;

    DefaultData.BuildingType.RoomTypes.forEach((roomsType: any, index:number) => {
        if(roomsType.Room.Name === defaultRoom) {
            defaultRoomIndex = index;
            const DefaultRoomType = roomsType.Room.DefaultRoomType;
            roomsType.Room.RoomTypes.forEach((r:any, i:number) => {
                if(DefaultRoomType === r.RoomType.Name) {
                    defaultRoomtypeIndex = i;
                }
            })
        }
    })

    return {defaultRoomIndex , defaultRoomtypeIndex}
}

export const DefaultBuildingAction = (DefaultData: BuildingType) => {
    return async (dispatch: Dispatch<Action>) => {
         const data:any = getDefaultRoomValues(DefaultData);
         reduxStore.dispatch(setSelectedRoomIndex(data.defaultRoomIndex))
        //  reduxStore.dispatch(setSelectedRoomTypeIndex(data.defaultRoomtypeIndex))
        if (DefaultData.BuildingType.Name && DefaultData.BuildingType.DefaultRoom) {
            //initial(default) request send to backend
            buildingTypeSelect(DefaultData.BuildingType.Name, DefaultData.BuildingType.DefaultRoom)
            roomSelectedWithCamera(DefaultData.BuildingType.DefaultRoom, 1)
        }
        if(isMobile) {
            dispatch({
                type: ActionType.BUILDING_DATA,
                payload: DefaultData
            })
        }
    }
}

export const SelectedRoomTypeAction = (roomSelected: string) => {

    return {
        type: ActionType.SELECTED_ROOM_TYPE,
        payload: roomSelected
    }
}
export const CompassDataAction=(Data:any)=>{
    return{
        type:ActionType.COMPASS_DATA,
        payload:Data
    } 
}


export const CurrentDefaultActiveRoomType=(Data:any)=>{
    return{
        type:ActionType.CURRENT_DEFAULT_ACTIVE_ROOMTYPE,
        payload:Data
    } 
}

