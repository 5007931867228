import { ActionType } from "../action-types";
import { Action } from "../actions/mobile";
import { mobileType } from "../../type/MobileType";


const INITIAL_STATE:mobileType  = {
    dropdownOpen: null,
    isOpenRoofView: false
}

export default (state = INITIAL_STATE, action:Action) => {
    switch (action.type) {
        case ActionType.MOBILE_DROPDOWN_OPEN:
            return {...state, dropdownOpen: action.payload}
        case ActionType.ROOF_VIEW_TOGGLE:
            return {...state, isOpenRoofView: !state.isOpenRoofView}
        default:
            return state
    }
}