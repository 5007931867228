import { ActionType } from "../action-types";
import { Action } from "../actions/allapp";
import  {AllAppObjType}  from '../../type/AllAppType';

const AllApp = {
    // mode:'Manual',
    loaderBar:false,
    is_page_load:false
}

export const INITIAL_STATE: AllAppObjType = {
    loaderBar:false,
    is_page_load:false
}

export default (state: AllAppObjType = INITIAL_STATE, action: Action): AllAppObjType => {

    switch (action.type) {
        //LOADING EVENTS START HERE
        case ActionType.ALL_APP:
            return { ...state, loaderBar: action.payload }
        case "is_web_page_load":
            return {...state, is_page_load: action.payload}
        default:
            return state;
    }

};