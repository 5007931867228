import { navbarType } from "../../../Types";
import NavbarItem from "./NavbarItem";



const NavbarBuildingView = (props: navbarType) => {
    return (
        <NavbarItem 
            isToggleOpen={props.isToggleOpen[0]} 
            handleUpdateToggleState={props.handleUpdateToggleState} 
            index={0} 
            activeClass='buliding_active' 
            activeSrc='Vector' 
            inactiveSrc='VectorWhite'
            step="step step-6"
        />
    );
    }


    export default NavbarBuildingView;