import { Compass } from "../../rightsection/Compass"

const MobileCompass = ({isFullScreen}:any) => {

    const angle = Compass(isFullScreen)

    return <div className="clockIc_landscape step step-5">
        <div className="compass">
            <div className="compass-inner">
                <div className="north">N</div>
                <div className="east">E</div>
                <div className="west">W</div>
                <div className="south">S</div>
                <div className="main-arrow" style={{ transform: `rotate(${angle && angle}deg)` }}>
                    <div className="arrow-up"></div>
                    <div className="arrow-down"></div>
                </div>
            </div>
        </div>
    </div>
}


export default MobileCompass;