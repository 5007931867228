import { Fragment } from "react";
import "./MobileDescriptionModal.css";
import { Price } from "../../../CommonDescriptionModal/Price";
import { Color } from "../../../CommonDescriptionModal/Color";
import { CloseModal } from "../../../CommonDescriptionModal/CloseModal";
import { Description } from "../../../CommonDescriptionModal/Description";
import { ModalItems } from "../../../CommonDescriptionModal/ModalItems";
import UseStyleDynamically from "../../../CommonDescriptionModal/UseStyle";
import { ModalContainer } from "../../../CommonDescriptionModal/ModalContainer";
import { DescriptionHeader } from "../../../CommonDescriptionModal/DescriptionHeader";
import "../../../CommonDescriptionModal/DescriptionModalCss/DescriptionModalWeb.css";

const MobileDescriptionModal = () => {
  const { componentList, onCloseModal, toShowDoubleListOfDescriptionModal } =
    UseStyleDynamically();

  return (
    <Fragment>
      <ModalItems
        className={{
          divRow: "row",
          divCol: "col-md-12",
          divSetVariations: "set_variations",
        }}
      />
      <div className="building_sections">
        <ModalContainer
          className={{
            divRow: "row",
            divMainSet: "main_set",
            divContainer: "container",
            divSetInnerBg: "set_inner_bg",
          }}
          toShowDoubleListOfDescriptionModal={
            toShowDoubleListOfDescriptionModal
          }
          componentList={componentList}
          // CloseModal={
          //   <CloseModal
          //     className="close_icon_right"
          //     onCloseModal={onCloseModal}
          //   />
          // }
          DescriptionHeader={
            <DescriptionHeader
              header={componentList.Header}
              className={{
                divRow: "row heading-section",
                div_col_09: " my-auto p-0",
                div_col_03: "",
                fortisImg:"fortis-img"
              }}
            />
          }
          Description={
            <Description
              description={componentList.Description}
              className={{
                descriptionStyle: "col-md-12 col-sm-12 description_border",
                divRow: "row ",
              }}
            />
          }
          Price={
            <Price
              price={componentList.Price}
              className={{
                div_col_06: "col-md-6 col-6",
                divRow: "row price-buy-now",
                // divTag: "col-sm-4",
                spanTag: "current_price",
              }}
            />
          }
          Color={
            <Color
              color={componentList.Color}
              className={{
                row: "row",
                color_section: "color-section",
                col_md_02: "col-md-2",
                col_md_10: "col-md-10",
                fabric_color: "fabric-color",
              }}
            />
          }
        />
      </div>
    </Fragment>
  );
};

export default MobileDescriptionModal;
