import { store as reduxStore } from "../../state";
import { ActionType } from "../../state/action-types";
import { useTypedSelector } from "../../hooks/use-typed-selector";
import { componentUnSelection } from "../../services/componentServices";


const UseStyleDynamically = () => {
    const {
        currentUpdatedItems: { 
            componentList 
        }
    } = useTypedSelector(({descriptionModal}) => descriptionModal);

    const {selectedComponentRoom} = useTypedSelector(({component}) => component);


    const isPriceAvailable = componentList.Price
    const isColorAvailable = componentList.Color

    const toShowDoubleListOfDescriptionModal = () => {
        if(isPriceAvailable === null && isColorAvailable === null){
            return "Single_Description"
        }
        if(isPriceAvailable === null || isColorAvailable === null){
            return 'Product_Description'
        }else{
            return ""
        }
    }

    const onCloseModal = () => {
        componentUnSelection(selectedComponentRoom);
        reduxStore.dispatch({
            type:ActionType.SELECTED_COMPONENT_ITEM,
            payload: ""
        })
    }

    return {
        componentList,
        onCloseModal,
        toShowDoubleListOfDescriptionModal
    };
}

export default UseStyleDynamically;