
import { Dispatch } from "redux";
import { ActionType } from '../action-types';
import { Action } from "../actions/component";

export const selectedComponentItem = (selectedComponentRoom: string | null) => {
    return {
        type: ActionType.SELECTED_COMPONENT_ITEM,
        payload: selectedComponentRoom
    }
}


export const componentCurrentState = (data: boolean | null) => {
    return {
        type: ActionType.SELECT_AND_UNSELECT_STATE,
        payload: data
    }
}