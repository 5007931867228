import React  from 'react';

const AnimationGifs = () => {
  return (
    <div className="move_screen">
    <div className="swipe">
        <div className="path"><svg xmlns="http://www.w3.org/2000/svg" width="120" height="50" viewBox="0 0 120 80" fill="none">
            <path d="M57.4706 11.1739V28.6315H62.7963V11.1739L73.3146 21.6868L77.0799 17.9215L60.1335 0.980469L43.1871 17.9215L46.9523 21.6868L57.4706 11.1739Z" fill="white" fillOpacity="0.8" />
            <path d="M102.792 43.6387L99.0271 47.4039L109.545 57.9222H92.0877V63.2479H109.545L99.0271 73.7662L102.792 77.5315L119.739 60.5851L102.792 43.6387Z" fill="white" fillOpacity="0.8" />
            <path d="M21.2399 47.4039L17.4746 43.6387L0.528198 60.5851L17.4746 77.5315L21.2399 73.7662L10.7216 63.2479H28.1793V57.9222H10.7216L21.2399 47.4039Z" fill="white" fillOpacity="0.8" />
            <path d="M60.1335 41.9414C49.8549 41.9414 41.4936 50.3028 41.4936 60.5814C41.4936 70.86 49.8549 79.2213 60.1335 79.2213C70.4121 79.2213 78.7735 70.86 78.7735 60.5814C78.7735 50.3028 70.4121 41.9414 60.1335 41.9414ZM60.1335 73.8956C52.7947 73.8956 46.8193 67.9255 46.8193 60.5814C46.8193 53.2372 52.7947 47.2671 60.1335 47.2671C67.4723 47.2671 73.4478 53.2372 73.4478 60.5814C73.4478 67.9255 67.4723 73.8956 60.1335 73.8956Z" fill="white" fillOpacity="0.8" />
        </svg></div>
        <div className="hand-icon"></div>
    </div>
</div>
  );
};

export default AnimationGifs;
