import React, { useState } from "react";
import CameraIndexBar from "./CameraIndexBar";
import { isMobile } from "react-device-detect";
import { useActions } from "../../hooks/use-actions";
import { useTypedSelector } from "../../hooks/use-typed-selector";
import { selectedCamera } from "../../services/footernavbarServices";
import MobileCameraIndexHandler from "../mobileLandscape/FooterSection/MobileCameraIndexHandler";

export const FooterNavigation = ({isFullScreen, helpButtonToggle}:any) => {

  const { setCameraIndex } = useActions();
  const { gettingTotalCameras } = MobileCameraIndexHandler();
  //Get all roomList 
  const [isOpen, setIsOpen] = useState(false);
  const { cameraIndex: activeCamera } = useTypedSelector(({media}) => media);

  const { 
    numberOfCamerasAvailable, 
    setDefaultMovingRoom
  } = useTypedSelector(({footernavbar}) => footernavbar);

  const { activeRoofView } = useTypedSelector(({ allscreens }) => allscreens);
  const { currentActiveRoomtype } = useTypedSelector(({ navbar }) => navbar);

  const onCameraSelect = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const selectedCam = event.currentTarget.getAttribute('data-cam');
    if (selectedCam) {
      setCameraIndex(parseInt(selectedCam))
      selectedCamera(selectedCam.toString())
    }
  }

  const handleNextArrowButton = () => {
    if (activeCamera) {
      let newCamera = activeCamera + 1
      if (activeCamera != numberOfCamerasAvailable?.length) {
        setCameraIndex(newCamera)
        selectedCamera(newCamera.toString())
      }
    }
  }

  const handlePreviewsArrowButton = () => {
    if (activeCamera) {
      let newCamera = activeCamera - 1
      if (newCamera >= 1) {
        setCameraIndex(newCamera)
        selectedCamera(newCamera.toString())
      }
    }
  }

  const handleActiveSubcategory = (item:any, isRoomSingle?:string) => {
    if(isRoomSingle !== "Single") {
      setIsOpen(item)
    }else {
      setIsOpen(false)
    }
  }

  return (
    <div onContextMenu={(e) => e.preventDefault()} onClick={(e) => e.stopPropagation()} className="col-md-8">
      <div className={`middle-class ${isMobile && 'mobile_landscape_cameraView'}`} id="pagination">
      <CameraIndexBar
          isOpen= {isOpen}
          isFullScreen={isFullScreen}
          activeCamera = {activeCamera}
          onCameraSelect = {onCameraSelect}
          activeRoofView = {activeRoofView}
          gettingTotalCameras = {gettingTotalCameras}
          currentActiveRoomtype = {currentActiveRoomtype}
          handleNextArrowButton = {handleNextArrowButton}
          setDefaultMovingRoom={setDefaultMovingRoom.Room}
          handleActiveSubcategory = {handleActiveSubcategory}
          numberOfCamerasAvailable = {numberOfCamerasAvailable} 
          handlePreviewsArrowButton = {handlePreviewsArrowButton} 
          helpButtonToggle={helpButtonToggle}
        />
      </div>
    </div>
  );
}