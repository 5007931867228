
interface DescriptionType {
    description: string,
    className: {
        descriptionStyle:string,
        divRow:string
    }
}


export const Description = (props: DescriptionType) => {

    return (
        <div className={props.className.divRow}>
        <div className={props.className.descriptionStyle}>
            <h4>Description</h4>
            <p>{props.description}</p>
        </div>
        </div>
    );
}