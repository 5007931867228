
interface DescriptionHeader {
    className: {
        divRow: string,
        div_col_09: string,
        div_col_03 :string,
        fortisImg:string
    },
    header: string
}

export const DescriptionHeader = (props: DescriptionHeader) =>{

    return (
        <div className={props.className.divRow}>
            <div className={props.className.fortisImg}>
                {/* <h3>{props.header}</h3> */}
              <img src="/images/des-logo.png" alt="logo" />
            </div>
            <div className={props.className.div_col_09}>
                {/* <h3>{props.header}</h3> */}
                <h3>high black sofa chair</h3>
            </div>
        </div>
    );
}