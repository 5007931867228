import { Webrtc } from "./webrtc";

export const onmouseDown = (x: number, y: number, buttonType: number) => {
  // console.log('onmouseDown')
  const mousePointJson = {
    C_Type: "WtoE",
    WtoE_Input: "Mouse",
    Mouse_Input: {
      State: "Pressed",
      Button: buttonType,
      X: x,
      Y: y,
    },
  };
  console.log("onmouseDown", buttonType);
  //Webrtc.sendJSON(mousePointJson);
  Webrtc.sendMouseButton(buttonType, true);
};

export function touchInputStart(x: any, y: any, numberOfTouchInput: number) {
  // console.log('touchInputStart')
  const touchInputStartResponse = {
    C_Type: "WtoE",
    WtoE_Input: "Touch",
    Touch_Input: {
      State: "Pressed",
      // "Index" :  `${numberOfTouchInput > 1 ? 1 : 0}`,
      Index: numberOfTouchInput,
      X: x,
      Y: y,
    },
  };
  // console.log("***touchInputStartResponse***",touchInputStartResponse)
  Webrtc.sendJSON(touchInputStartResponse);
}

export function touchInputPressed(x: any, y: any, numberOfTouchInput: number) {
  // console.log('touchInputPressed')
  const touchInputPressedResponse = {
    C_Type: "WtoE",
    WtoE_Input: "Touch",
    Touch_Input: {
      State: "Moved",
      Index: numberOfTouchInput,
      // "Index": 0,
      X: x,
      Y: y,
    },
  };
  // console.log("***touchInputPressedResponse***",touchInputPressedResponse)
  Webrtc.sendJSON(touchInputPressedResponse);
}

export function touchInputReleased(
  x: number | undefined,
  y: number | undefined,
  numberOfTouchInput: number
) {
  // console.log('touchInputReleased')
  const touchInputReleasedResponse = {
    C_Type: "WtoE",
    WtoE_Input: "Touch",
    Touch_Input: {
      State: "Released",
      // "Index" : `${numberOfTouchInput > 1 ? 1 : 0}`,
      Index: numberOfTouchInput,
      X: x,
      Y: y,
    },
  };
  // console.log("***touchInputReleasedResponse***",touchInputReleasedResponse)
  Webrtc.sendJSON(touchInputReleasedResponse);
}

export const onMouseMoveType = (x: number, y: number) => {
  // console.log('onMouseMoveType')
  const mouseMovePosition = {
    C_Type: "WtoE",
    WtoE_Input: "Mouse",
    Mouse_Input: {
      State: "Moved",
      X: x,
      Y: y,
    },
  };
  // console.log(mouseMovePosition)
  //Webrtc.sendJSON(mouseMovePosition);
  Webrtc.sendMousePosition(x, y);
};

export const mouseRelease = (x: number, y: number, buttonType: number) => {
  // console.log('mouseRelease')
  const mouseReleaseJson = {
    C_Type: "WtoE",
    WtoE_Input: "Mouse",
    Mouse_Input: {
      Button: buttonType,
      State: "Released",
      X: x,
      Y: y,
    },
  };

  // console.log("buttoncheck",mouseReleaseJson)
  //Webrtc.sendJSON(mouseReleaseJson);

  Webrtc.sendMouseButton(buttonType, false);
};

export const scrollingMouseWheel = (scrollingPosition: number) => {
  // console.log('scrollingMouseWheel')
  const onMouseWheel = {
    C_Type: "WtoE",
    WtoE_Input: "Mouse",
    Mouse_Input: {
      State: scrollingPosition,
      Button: "Scroll",
    },
  };

  // console.log("onMouseWheel",onMouseWheel)
  Webrtc.sendMouseWheel(scrollingPosition);
};
